"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const AbstractTokenizer_1 = require("./AbstractTokenizer");
const type_1 = require("./type");
const then_read_stream_1 = require("then-read-stream");
const _debug = require("debug");
const debug = _debug('strtok3:ReadStreamTokenizer');
class ReadStreamTokenizer extends AbstractTokenizer_1.AbstractTokenizer {
    constructor(stream, fileSize) {
        super();
        this.streamReader = new then_read_stream_1.StreamReader(stream);
        this.fileSize = fileSize;
    }
    /**
     * Read buffer from stream
     * @param buffer
     * @param offset is the offset in the buffer to start writing at; if not provided, start at 0
     * @param length is an integer specifying the number of bytes to read
     * @returns Promise number of bytes read
     */
    readBuffer(buffer, offset = 0, length = buffer.length, position) {
        return __awaiter(this, void 0, void 0, function* () {
            // const _offset = position ? position : this.position;
            // debug(`readBuffer ${_offset}...${_offset + length - 1}`);
            if (length === 0) {
                return 0;
            }
            if (position) {
                const skipBytes = position - this.position;
                if (skipBytes > 0) {
                    yield this.ignore(position - this.position);
                    return this.readBuffer(buffer, offset, length);
                }
                else if (skipBytes < 0) {
                    throw new Error('Cannot read from a negative offset in a stream');
                }
            }
            let bytesRead;
            try {
                bytesRead = yield this.streamReader.read(buffer, offset, length);
                this.position += bytesRead;
            }
            catch (err) {
                if (err.message === then_read_stream_1.endOfStream) // Convert EndOfStream into EndOfFile
                    throw new Error(type_1.endOfFile);
                else
                    throw err;
            }
            if (bytesRead < length) {
                throw new Error(type_1.endOfFile);
            }
            return bytesRead;
        });
    }
    /**
     * Peek (read ahead) buffer from tokenizer
     * @param buffer
     * @param offset is the offset in the buffer to start writing at; if not provided, start at 0
     * @param length is an integer specifying the number of bytes to read
     * @param position is an integer specifying where to begin reading from in the file. If position is null, data will be read from the current file position.
     * @param maybeless If set, will not throw an EOF error if the less then the requested length could be read
     * @returns {Promise<number>}
     */
    peekBuffer(buffer, offset = 0, length = buffer.length, position, maybeless) {
        return __awaiter(this, void 0, void 0, function* () {
            // const _offset = position ? position : this.position;
            // debug(`peek ${_offset}...${_offset + length - 1}`);
            let bytesRead;
            if (position) {
                const skipBytes = position - this.position;
                if (skipBytes > 0) {
                    const skipBuffer = Buffer.alloc(length + skipBytes);
                    bytesRead = yield this.peekBuffer(skipBuffer, 0, skipBytes + length, undefined, maybeless);
                    skipBuffer.copy(buffer, offset, skipBytes);
                    return bytesRead - skipBytes;
                }
                else if (skipBytes < 0) {
                    throw new Error('Cannot peek from a negative offset in a stream');
                }
            }
            try {
                bytesRead = yield this.streamReader.peek(buffer, offset, length);
            }
            catch (err) {
                if (err.message === then_read_stream_1.endOfStream) // Convert EndOfStream into EndOfFile
                    throw new Error(type_1.endOfFile);
                else
                    throw err;
            }
            if (!maybeless && bytesRead < length) {
                throw new Error(type_1.endOfFile);
            }
            return bytesRead;
        });
    }
    ignore(length) {
        return __awaiter(this, void 0, void 0, function* () {
            debug(`ignore ${this.position}...${this.position + length - 1}`);
            // debug(`Ignore ${length} bytes in a stream`);
            const buf = Buffer.alloc(length);
            return this.readBuffer(buf); // Stream cannot skip data
        });
    }
}
exports.ReadStreamTokenizer = ReadStreamTokenizer;
