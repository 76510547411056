"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const type_1 = require("./type");
class BufferTokenizer {
    constructor(buffer) {
        this.buffer = buffer;
        this.position = 0;
        this.fileSize = buffer.length;
    }
    /**
     * Read buffer from tokenizer
     * @param buffer
     * @param offset is the offset in the buffer to start writing at; if not provided, start at 0
     * @param length is an integer specifying the number of bytes to read
     * @param position is an integer specifying where to begin reading from in the file. If position is null, data will be read from the current file position.
     * @returns {Promise<TResult|number>}
     */
    readBuffer(buffer, offset, length, position) {
        return __awaiter(this, void 0, void 0, function* () {
            this.position = position || this.position;
            return this.peekBuffer(buffer, offset, length, this.position).then(bytesRead => {
                this.position += bytesRead;
                return bytesRead;
            });
        });
    }
    /**
     * Peek (read ahead) buffer from tokenizer
     * @param buffer
     * @param offset is the offset in the buffer to start writing at; if not provided, start at 0
     * @param length is an integer specifying the number of bytes to read
     * @param position is an integer specifying where to begin reading from in the file. If position is null, data will be read from the current file position.
     * @param maybeLess If true, will return the bytes available if available bytes is less then length.
     * @returns {Promise<TResult|number>}
     */
    peekBuffer(buffer, offset, length, position, maybeLess = false) {
        return __awaiter(this, void 0, void 0, function* () {
            position = position || this.position;
            if (!length) {
                length = buffer.length;
            }
            const bytes2read = Math.min(this.buffer.length - position, length);
            if (!maybeLess && bytes2read < length) {
                throw new Error(type_1.endOfFile);
            }
            else {
                this.buffer.copy(buffer, offset, position, position + bytes2read);
                return bytes2read;
            }
        });
    }
    readToken(token, position) {
        return __awaiter(this, void 0, void 0, function* () {
            this.position = position || this.position;
            try {
                const tv = this.peekToken(token, this.position);
                this.position += token.len;
                return tv;
            }
            catch (err) {
                this.position += this.buffer.length - position;
                throw err;
            }
        });
    }
    peekToken(token, position = this.position) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.buffer.length - position < token.len) {
                throw new Error(type_1.endOfFile);
            }
            return token.get(this.buffer, position);
        });
    }
    readNumber(token) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.readToken(token);
        });
    }
    peekNumber(token) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.peekToken(token);
        });
    }
    /**
     * @return actual number of bytes ignored
     */
    ignore(length) {
        return __awaiter(this, void 0, void 0, function* () {
            const bytesIgnored = Math.min(this.buffer.length - this.position, length);
            this.position += bytesIgnored;
            return bytesIgnored;
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            // empty
        });
    }
}
exports.BufferTokenizer = BufferTokenizer;
