"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const type_1 = require("./type");
class AbstractTokenizer {
    constructor() {
        this.position = 0;
        this.numBuffer = Buffer.alloc(4);
    }
    readToken(token, position = null, maybeless) {
        return __awaiter(this, void 0, void 0, function* () {
            const buffer = Buffer.alloc(token.len);
            const len = yield this.readBuffer(buffer, 0, token.len, position);
            if (!maybeless && len < token.len)
                throw new Error(type_1.endOfFile);
            return token.get(buffer, 0);
        });
    }
    peekToken(token, position = this.position, maybeless) {
        return __awaiter(this, void 0, void 0, function* () {
            const buffer = Buffer.alloc(token.len);
            const len = yield this.peekBuffer(buffer, 0, token.len, position);
            if (!maybeless && len < token.len)
                throw new Error(type_1.endOfFile);
            return token.get(buffer, 0);
        });
    }
    readNumber(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const len = yield this.readBuffer(this.numBuffer, 0, token.len, null);
            if (len < token.len)
                throw new Error(type_1.endOfFile);
            return token.get(this.numBuffer, 0);
        });
    }
    peekNumber(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const len = yield this.peekBuffer(this.numBuffer, 0, token.len);
            if (len < token.len)
                throw new Error(type_1.endOfFile);
            return token.get(this.numBuffer, 0);
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            // empty
        });
    }
}
exports.AbstractTokenizer = AbstractTokenizer;
