{
  "images": {
    "EQ_PREAMP_LINE":
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAABCAYAAADpXEERAAAAE0lEQVQoU2Pcdfruf4ZRMKRDAAD1lwNjTqcaUQAAAABJRU5ErkJggg==",
    "EQ_GRAPH_LINE_COLORS":
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAATCAYAAABRC2cZAAAAR0lEQVQYV2O4rCT9n+F9kOJ/hvfViv8ZHkzSQCE2afxneH/HEJm49Nr0PwOYWPLIAkp0PjL4z1B41uQ/Q9QGnf8MWrPEIAQANWYwvnlToNIAAAAASUVORK5CYII="
  },
  "colors": [
    "rgb(0,0,0)",
    "rgb(24,33,41)",
    "rgb(239,49,16)",
    "rgb(206,41,16)",
    "rgb(214,90,0)",
    "rgb(214,102,0)",
    "rgb(214,115,0)",
    "rgb(198,123,8)",
    "rgb(222,165,24)",
    "rgb(214,181,33)",
    "rgb(189,222,41)",
    "rgb(148,222,33)",
    "rgb(41,206,16)",
    "rgb(50,190,16)",
    "rgb(57,181,16)",
    "rgb(49,156,8)",
    "rgb(41,148,0)",
    "rgb(24,132,8)",
    "rgb(255,255,255)",
    "rgb(214,214,222)",
    "rgb(181,189,189)",
    "rgb(160,170,175)",
    "rgb(148,156,165)",
    "rgb(150,150,150)"
  ],
  "playlistStyle": {
    "normal": "#00FF00",
    "current": "#FFFFFF",
    "normalbg": "#000000",
    "selectedbg": "#0000FF",
    "font": "Arial"
  }
}
